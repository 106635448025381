import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config";

function MagForm() {
  const [email, setEmail] = useState("");
  const [code, setCode] = useState("");
  const [accepted, setAccepted] = useState(false);
  const navigate = useNavigate();

  const handleCodeChange = (event) => {
    let inputCode = event.target.value.toUpperCase();
    // if (inputCode.length === 2 || inputCode.length === 5) {
    //   inputCode += "-";
    // }
    setCode(inputCode.slice(0, 12)); // we slice here to ensure the length never goes above the expected format "XX-XX-XXXXXX"
  };

  const validateEmail = (email) => {
    // Une expression régulière simple pour une validation d'email basique
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(String(email).toLowerCase());
  };

  // const validateCode = (code) => {
  //   // Valide les codes de type XX-XX-XXXXXX
  //   const codeRegex = /^[A-Z0-9]{2}-[A-Z0-9]{2}-[A-Z0-9]{1,6}$/;
  //   return codeRegex.test(String(code).toUpperCase());
  // };
  const validateCode = (code) => {
    // Valide les codes qui ont une length de 5 à 10 caractères
    const codeRegex = /^[A-Z0-9]{4,10}$/;
    return codeRegex.test(String(code).toUpperCase());
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!validateEmail(email)) {
      alert("Veuillez entrer une adresse email valide.");
      return;
    }
    if (!validateCode(code)) {
      alert("Veuillez entrer un code au bon format.");
      return;
    }

    try {
      const response = await axios.post(config.api + "/mags", {
        email: email,
        code: code,
      });
      if (response.data.message === "Created") {
        navigate("/confirmation");
      } else {
        console.log(response);
        alert(response.data.message);
      }
    } catch (error) {
      console.log(error);
      alert("Une erreur est survenue, veuillez réessayer plus tard.");
    }
  };

  return (
    <form onSubmit={handleSubmit} id="magForm">
      <label>
        Adresse Email: <br />
        <div>
          <input
            type="email"
            value={email}
            placeholder="Votre adresse Email"
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
      </label>
      <label>
        Code:
        <div>
          <input
            type="text"
            name="code"
            value={code}
            onChange={handleCodeChange}
            placeholder="Code affiché sur la borne"
            required
          />
        </div>
      </label>
      <label className="checkbox-div">
        <input
          type="checkbox"
          checked={accepted}
          onChange={(e) => setAccepted(e.target.checked)}
          className="checkboxInput"
          required
        />
        <span className="cgv">
          J'accepte les{" "}
          <a href={"/cgv"} target={"_blank"} id={"cgvLink"}>
            conditions générales
          </a>
        </span>
      </label>
      <button type="submit">Valider</button>
    </form>
  );
}

export default MagForm;
