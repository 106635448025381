import React, { useEffect, useState } from "react";

function AllMags(props) {
  const [magazines, setMagazines] = useState([]);
  const [tkn, setTkn] = useState(null);

  useEffect(() => {
    setTkn(props.tkn);
  }, [props.tkn]);

  useEffect(() => {
    setMagazines(props.magazines);
  }, [props.magazines]);

  return magazines.map((magazine, index) => {
    let mediumImage = magazine.imgCoverUrl.replace(
      "cover-small.jpg",
      "cover-medium.jpg"
    );
    return (
      <article key={index}>
        <a
          href={magazine.openDocUrl + "&token=" + tkn + "&context=inapp"}
          target="_blank"
          title={magazine.pubTitle}
        >
          <div className="magazine-pad">
            <img src={mediumImage} alt={magazine.pubTitle} />
            <footer>
              <div className="magazine-infos-pad">
                <h2>{magazine.pubTitle}</h2>
              </div>
            </footer>
          </div>
        </a>
      </article>
    );
  });
}

export default AllMags;
