import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./style/App.css";
import "./style/Mobile.scss";
import MagazinePage from "./pages/magazinePage";
import FormPage from "./pages/formPage";
import ConfirmPage from "./pages/confirmPage";
import CgvPage from "./pages/cgvPage";
import CookieConsent from "react-cookie-consent";

function App() {
  const confirm = {
    title: "Inscription confirmée !",
    text: [
      `Un mail va vous être envoyé pour accéder à notre catalogue.`,
      `On passe à la librairie pour aller chercher les magazines...`,
      `Cela peut prendre quelques minutes…`,
    ],
  };
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route exact path="/mags/:tkn" element={<MagazinePage />} />
          <Route
            exact
            path="/confirmation"
            element={<ConfirmPage txt={confirm} />}
          ></Route>
          <Route exact path="/cgv" element={<CgvPage />}></Route>
          <Route path="/" element={<FormPage />}></Route>
        </Routes>
        <CookieConsent
          location="bottom"
          buttonText="Ok"
          cookieName="globalCookie"
          style={{ background: "#f8b5a8", fontSize: "13px" }}
          buttonStyle={{
            color: "black",
            fontSize: "13px",
            background: "#99c8be",
          }}
          expires={150}
        >
          Ce site n'utilise que des cookies fonctionnels. <u>Aucun tracking</u> {" "}
          n'est effectué afin de préserver votre vie privée.
        </CookieConsent>
      </div>
    </Router>
  );
}

export default App;
