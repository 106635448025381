import React from "react";
import LisaLogo from "../assets/images/lisaconnect.png";
import "../style/cgvStyle.scss";
import { useNavigate } from "react-router-dom";

function CgvPage() {
  const navigate = useNavigate();

  return (
    <section id="cgvPage">
      <img
        src={LisaLogo}
        alt="logo lisaconnect"
        id="cgv-logo"
        onClick={() => navigate("/")}
      />
      <h1>Conditions Générales d'utilisation</h1>
      <div id="cgv-all">
        <div>
          {/* <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "center",
              borderLeft: "solid #000000 0.5pt",
              borderRight: "solid #000000 0.5pt",
              borderTop: "solid #000000 0.5pt",
              marginTop: "0pt",
              marginBottom: "0pt",
              padding: "1pt 4pt 8pt 4pt",
            }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              CONDITIONS GÉNÉRALES D’UTILISATION&nbsp;
            </span>
          </p> */}
          {/* <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "center",
              borderLeft: "solid #000000 0.5pt",
              borderRight: "solid #000000 0.5pt",
              borderBottom: "solid #000000 0.5pt",
              marginTop: "0pt",
              marginBottom: "8pt",
              padding: "0pt 4pt 1pt 4pt",
            }}
          >
            <span
              style={{
                fontSize: "13.999999999999998pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              BORNE LISA CONNECT
            </span>
          </p> */}
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              A.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;LISA CONNECT, société par actions simplifiée au capital de
              40.000 euros, dont le siège social est situé 53 rue de la Paix –
              10000 TROYES, immatriculée au registre du commerce et des sociétés
              de TROYES sous le numéro 882 662 190,
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;(Ci-après dénommée « LISA CONNECT »),
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;a conçu et développé le concept de bornes tactiles et totems
              interactifs informatisés&nbsp;
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              (Ci-après dénommés le(s) « PRODUIT(S) »),&nbsp;
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              en vue de la fourniture d’affichages numériques intelligents au
              sein de site d’accueils de patients et/ou de réalisation de
              soins&nbsp;
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              (Ci-après dénommés le(s) « SITE(S) »),&nbsp;
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              pour les établissements de soins ou les cabinets médicaux et
              paramédicaux
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;(Ci-après dénommés le(s) « PARTENAIRE(S) »),
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;et les sociétés tierces&nbsp;
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              (Ci-après dénommés le(s) « ANNONCEUR(S) »),&nbsp;
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              souhaitant réaliser une activité de promotion de messages à teneur
              pédagogique et informative, en matières de santé et de prévention
              sous forme de diffusion de contenus digitaux&nbsp;
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              (Ci-après dénommés le(s) « CONTENU(S )»)
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;sur les PRODUITS, accessibles gratuitement aux utilisateurs
              autorisés&nbsp;
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              (Ci-après dénommés le(s) « UTILISATEUR(S) »)
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ,
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              par visualisation directe ou par téléchargement sur le réseau
              internet, via lecture de flashcodes depuis un support
              électronique.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR est défini comme toute personne physique majeure
              désirant accéder à titre personnel et non professionnel, aux
              CONTENUS diffusés sur le PRODUIT durant la période de présence sur
              SITE et/ou de connexion sur le PRODUIT.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LISA CONNECT et l’UTILISATEUR sont ci-après dénommées ensemble les
              « Parties ».
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              B.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;Ainsi par l’intermédiaire du PRODUIT mis à leur disposition
              par LISA CONNECT, l’UTILISATEUR&nbsp;
            </span>

            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              accède à titre gracieux aux CONTENUS élaborés par les PARTENAIRES
              ou les ANNONCEURS.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2999999999999998",
              textAlign: "justify",
              marginTop: "6pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Les présentes conditions générales d’utilisation&nbsp;
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              (Ci-après dénommées les « CGU »)
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;s'appliquent, sans restriction ni réserve à toute
              utilisation du PRODUIT par l’UTILISATEUR.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2999999999999998",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Les CGU sont systématiquement communiquées à l’UTILISATEUR
              préalablement à toute utilisation, et prévaudront, le cas échéant,
              sur toute autre version ou tout autre document contradictoire.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2999999999999998",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              C.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;L’UTILISATEUR déclare avoir pris connaissance des CGU et les
              avoir acceptées sans restriction ni réserve, ce préalablement à
              toute utilisation.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              La connexion au PRODUIT emporte l’acceptation sans réserve des CGU
              par l’UTILISATEUR.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              En cochant la case « Je certifie avoir pris connaissance et
              accepter les Conditions Générales d’Utilisation du PRODUIT »,
              l’UTILISATEUR reconnaît avoir pris connaissance, avoir compris,
              accepter et s’engage à respecter les CGU dans leur intégralité.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2999999999999998",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR pourra refuser les CGU totalement ou partiellement,
              mais dans ce cas il ne pourra pas accéder au PRODUIT.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2999999999999998",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Sauf preuve contraire, les données enregistrées dans le système
              informatique de LISA CONNECT constituent la preuve de l'ensemble
              des transactions conclues avec l’UTILISATEUR.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 1 – OBJET
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Les CGU ont pour objet de définir les modalités d’utilisation du
              PRODUIT et d’accès aux CONTENUS, par l’UTILISATEUR.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LISA CONNECT se réserve le droit de modifier à tout moment les
              CGU. Dans la mesure du possible, l’UTILISATEUR sera informé de ces
              modifications préalablement à leur entrée en vigueur.
              L’UTILISATEUR est invité à consulter régulièrement les CGU pour
              prendre connaissance des éventuelles modifications qui pourraient
              y être apportées.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Aucune des Parties ne peut prendre un engagement au nom et pour le
              compte de l’autre Partie. Ainsi, chacune des Parties s’engage à ne
              rien faire qui puisse induire en erreur un tiers à cet égard, ni
              prendre aucun engagement, ni offrir une quelconque garantie au nom
              de l’autre Partie.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 2 – DESCRIPTION DU PRODUIT
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Le PRODUIT est réservé aux seuls UTILISATEURS dûment autorisés par
              LISA CONNECT.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chaque UTILISATEUR bénéficie d’un accès strictement personnel et
              limité aux seules informations autorisées par LISA CONNECT.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Le PRODUIT pourra mettre à disposition de l’UTILISATEUR
              différentes fonctionnalités telles que l'accès temporaire à des
              magazines digitaux, des vidéos, etc...
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "#f4cccc",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            ></span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              .&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Il est précisé que LISA CONNECT se réserve la possibilité de faire
              évoluer le PRODUIT et ses différentes fonctionnalités à tout
              moment. LISA CONNECT en informera l’UTILISATEUR dans les meilleurs
              délais par tout moyen d’usage.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chaque UTILISATEUR dispose de la possibilité, via un module du
              PRODUIT prévu à cet effet, de proposer toute suggestion, demande
              d'amélioration ou de correction, recommandation ou tout autre
              retour concernant le fonctionnement du PRODUIT.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR reconnaît que LISA CONNECT peut poser des règles
              générales et des limites quant à l'utilisation du PRODUIT, et
              notamment, sans que cette énumération soit limitative, fixer un
              nombre de jours maximum pendant lesquels les informations
              contenues sur le PRODUIT seront conservées, fixer un nombre
              maximum de consultations et/ou d’impressions des informations du
              PRODUIT, fixer un maximum au nombre de fois où l’UTILISATEUR peut
              accéder au PRODUIT et/ou aux CONTENUS pendant une période donnée,
              ainsi que la durée maximale de chaque accès.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LISA CONNECT sera également libre de supprimer, sans formalité ni
              préavis, tout compte resté inutilisé pendant un certain temps.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LISA CONNECT se réserve le droit, à tout moment, de modifier,
              interrompre temporairement ou de manière permanente tout ou partie
              du PRODUIT et de ses fonctionnalités, et ce sans avoir à en
              informer préalablement l’UTILISATEUR. LISA CONNECT ne pourra être
              tenue responsable en aucun cas à l’encontre de l’UTILISATEUR ou à
              l'encontre de tout tiers pour toute modification, suspension ou
              interruption du PRODUIT.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 3 – MISE A DISPOSITION DU PRODUIT
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              3.1.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;Aux termes des CGU, LISA CONNECT autorise l’UTILISATEUR à
              accéder aux différentes fonctionnalités du PRODUIT, ce à titre
              gracieux, à l’exclusion de tout processus commercial et/ou de
              vente, afin notamment d’accéder aux CONTENUS élaborés par les
              PARTENAIRES ou les ANNONCEURS.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              3.2.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;Il est entendu que le PRODUIT ne comporte aucune exclusivité
              au profit de l’UTILISATEUR.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              borderLeft: "solid #000000 0.5pt",
              borderRight: "solid #000000 0.5pt",
              borderTop: "solid #000000 0.5pt",
              marginTop: "0pt",
              marginBottom: "0pt",
              padding: "1pt 4pt 8pt 4pt",
            }}
          >
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              borderLeft: "solid #000000 0.5pt",
              borderRight: "solid #000000 0.5pt",
              marginTop: "0pt",
              marginBottom: "0pt",
              padding: "0pt 4pt 8pt 4pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              3.3.&nbsp;
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              AVERTISSEMENT
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;:
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              borderLeft: "solid #000000 0.5pt",
              borderRight: "solid #000000 0.5pt",
              marginTop: "0pt",
              marginBottom: "0pt",
              padding: "0pt 4pt 8pt 4pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Certains CONTENUS pourront comporter des liens hypertextes
              redirigeant directement vers des sites marchands commercialisant
              produits et/ou services payants, appartenant et gérés par les
              ANNONCEURS qui constituent des entités commerciales tierces à LISA
              CONNECT. Lesdits sites marchands comportent des conditions
              générales de vente afférentes strictement distinctes des
              CGU.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              borderLeft: "solid #000000 0.5pt",
              borderRight: "solid #000000 0.5pt",
              marginTop: "0pt",
              marginBottom: "0pt",
              padding: "0pt 4pt 8pt 4pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR n’a aucune obligation de cliquer sur des liens
              hypertextes redirigeant directement vers des sites marchands.
              L’UTILISATEUR prenant la décision de cliquer sur lesdits liens
              hypertextes réalise ce faisant une démarche personnelle sous sa
              seule et entière responsabilité. LISA CONNECT décline toute
              responsabilité quant aux dommages de toute nature susceptibles
              d’être générés par cette démarche à l’encontre de l’UTILISATEUR
              et/ou de ses biens.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              borderLeft: "solid #000000 0.5pt",
              borderRight: "solid #000000 0.5pt",
              borderBottom: "solid #000000 0.5pt",
              marginTop: "0pt",
              marginBottom: "8pt",
              padding: "0pt 4pt 1pt 4pt",
            }}
          >
            <br />
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 4 – MODALITES D’ACCES AU PRODUIT
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              4.1. Accès technique
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L'accès s'effectue à partir de n’importe quel support électronique
              (tablette, téléphone, ordinateur) connecté à internet.
              L’UTILISATEUR est seul chargé de configurer son support
              électronique afin d’être en mesure de se connecter au PRODUIT. Il
              doit disposer d’un accès internet, sous sa seule responsabilité.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR accède au PRODUIT en se connectant avec un
              navigateur internet. A la date de signature des CGU, et sous
              réserve des évolutions qui pourront être apportées au PRODUIT et
              des évolutions des standards techniques et/ou informatiques, le
              PRODUIT est compatible avec les navigateurs internet explorer,
              Safari, Firefox, et Chrome.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Le PRODUIT est entièrement développé et maintenu par LISA CONNECT.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              4.2. Modalités d’identification des UTILISATEURS – Compte de
              connexion
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tout UTILISATEUR doit être dûment autorisé par LISA CONNECT ;
              l’accès au PRODUIT est réservé aux seuls UTILISATEURS auxquels
              LISA CONNECT aura attribué un identifiant et un mot de passe.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Pour des raisons de sécurité et de confidentialité, seule la
              combinaison de ces deux codes permet aux UTILISATEURS d’accéder au
              PRODUIT, étant précisé qu’un cookie de session sera déposé sur
              l’équipement terminal de l’UTILISATEUR.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chaque UTILISATEUR s’engage à utiliser le PRODUIT dans un cadre
              exclusivement personnel, chaque compte dûment créé étant
              obligatoirement lié à l’adresse électronique personnelle de
              l’UTILISATEUR.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              À l'issue de la procédure d'inscription auprès de LISA CONNECT,
              chaque UTILISATEUR reçoit un identifiant et un mot de passe. Ce
              mot de passe peut être modifié à tout moment par l’UTILISATEUR. Il
              est recommandé à l’UTILISATEUR de modifier régulièrement et
              fréquemment son mot de passe.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Il est convenu entre les Parties que la communication en ligne de
              l’identifiant et du mot de passe du compte utilisateur dans les
              conditions fixées par les CGU vaut preuve de l'identité de
              l’UTILISATEUR.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            L'accès aux services n'est pas obligatoirement lié à une connexion
            au travers d'un compte utilisateur. LISA CONNECT se réserve le droit
            de limiter l'accès à certains services à des comptes utilisateurs
            spécifiques.
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’identifiant et le mot de passe sont destinés à réserver l'accès
              au PRODUIT à l’UTILISATEUR et à protéger l'intégrité, la
              disponibilité et la confidentialité des données utilisées dans le
              cadre du PRODUIT.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Le compte de connexion fera régulièrement l’objet d’une
              vérification, au minimum tous les trois mois, par l’envoi par
              courrier électronique à l’UTILISATEUR d’un code de sécurité à
              saisir sur son support électronique préalablement à l’utilisation
              du PRODUIT.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chaque UTILISATEUR est entièrement et exclusivement responsable de
              la conservation du caractère confidentiel de ses identifiant et
              mot de passe et de toutes actions qui pourront être faites sous
              son compte de connexion et/ou avec son mot de passe, et garantit
              LISA CONNECT contre toute réclamation à ce titre.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Toute publication d’informations contenues dans le PRODUIT
              effectuée à partir des codes d‘accès de l’UTILISATEUR sera réputée
              avoir été effectuée par l’UTILISATEUR lui-même et sous sa
              responsabilité exclusive, notamment si l’UTILISATEUR prend le
              risque de pré-enregistrer ses codes d’accès sur son support
              électronique et/ou communiqué à des tiers et/ou laisse accessible
              à des tiers ses codes d’accès. &nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chaque UTILISATEUR s’engage :
            </span>
          </p>
          <ul
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  À informer immédiatement LISA CONNECT, de toute utilisation
                  non autorisée de son compte et/ou de son mot de passe et/ou de
                  toute atteinte à la sécurité,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "8pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  À s’assurer qu'à l'issue de chaque session il se déconnecte du
                  PRODUIT. LISA CONNECT ne pourra être tenue responsable de
                  toute communication à des tiers, perte ou dommage survenant en
                  cas de manquement aux obligations des CGU.
                </span>
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LISA CONNECT met à disposition de l’UTILISATEUR un système de
              récupération du mot de passe oublié, lequel sera envoyé sur
              demande par courrier électronique à l’adresse électronique
              associée au compte de l’UTILISATEUR.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR dispose de la faculté de suspendre ou de supprimer,
              pour quelque motif que ce soit, son compte de connexion.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR bénéficie d’une assistance technique intégrée au
              sein du PRODUIT (pictogramme représentant un point
              d’interrogation) ou via l’adresse électronique :&nbsp;
            </span>
            <a
              href="mailto:contact@lisaconnect.fr"
              style={{ textDecoration: "none" }}
            >
              <span
                style={{
                  fontSize: "11pt",
                  fontFamily: "Cambria,serif",
                  color: "#0563c1",
                  backgroundColor: "transparent",
                  fontWeight: 700,
                  fontStyle: "normal",
                  fontVariant: "normal",
                  textDecoration: "underline",
                  WebkitTextDecorationSkip: "none",
                  textDecorationSkipInk: "none",
                  verticalAlign: "baseline",
                  whiteSpace: "pre-wrap",
                }}
              >
                contact@lisaconnect.fr
              </span>
            </a>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 5 – CONDITIONS D’UTILISATION DU SERVICE
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              5.1.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;Les informations intégrées sur le PRODUIT et/ou sur les
              CONTENUS présentent un caractère confidentiel ; le PRODUIT et les
              CONTENUS sont destinés à un usage strictement personnel.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR s’interdit toute utilisation du PRODUIT qui serait
              contraire à ce principe et notamment toute utilisation susceptible
              de porter préjudice à LISA CONNECT et/ou à un PARTENAIRE et/ou à
              un ANNONCEUR.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR s’interdit de télécharger, envoyer, transmettre par
              e-mail ou de toute autre manière toute information portant sur un
              CONTENU et/ou issue du PRODUIT, à des tiers qui ne seraient pas
              dûment autorisés par LISA CONNECT.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              5.2.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;LISA CONNECT se réserve le droit d’engager toute action à
              l’encontre de tout UTILISATEUR et/ou de toute personne physique ou
              morale qui ne respecterait pas les CGU.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Tout UTILISATEUR s’engage à titre personnel à garantir et
              indemniser LISA CONNECT et tout tiers contre tout dommage, plainte
              ou demande consécutif à l'envoi, l'affichage, le téléchargement,
              la diffusion ou la transmission d’informations contenues dans le
              PRODUIT par lui, en violation des CGU, ou des droits d'autrui ou
              de l'ordre public. Cette garantie couvre tant les sommes qui
              seraient dues pour indemniser les préjudices subis par LISA
              CONNECT, par les PARTENAIRES, par les ANNONCEURS et/ou par toute
              personne qui estimerait avoir subi un préjudice du fait des
              manquements de l’UTILISATEUR ainsi que les honoraires d'avocat et
              frais de justice.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              5.3.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;Chaque UTILISATEUR s’interdit de reproduire, copier, vendre,
              revendre, ou exploiter dans un but commercial quel qu'il soit
              toute partie du PRODUIT et/ou des CONTENUS, toute utilisation du
              PRODUIT et/ou des CONTENUS, ou tout droit d'accès au PRODUIT et/ou
              aux CONTENUS.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 6 – OBLIGATIONS ET RESPONSABILITES DE L’UTILISATEUR
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              6.1.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;L’UTILISATEUR reconnaît avoir reçu de LISA CONNECT toutes
              les informations nécessaires lui permettant d’apprécier
              l’adéquation du PRODUIT à ses besoins et de prendre toutes les
              précautions utiles pour l’utilisation du PRODUIT.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              6.2.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;L’UTILISATEUR garantit avoir reçu et disposer de l’ensemble
              des autorisations et droits lui permettant d’utiliser le PRODUIT
              et l’ensemble des fonctionnalités auxquelles LISA CONNECT lui
              donnera accès.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              6.3.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;L’UTILISATEUR garantit l’exactitude, l’exhaustivité et le
              caractère à jour des informations communiquées aux fins de
              création de son compte de connexion.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR accepte et reconnaît que l’utilisation du PRODUIT
              s’effectuera à ses risques et périls.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              6.4.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;L’UTILISATEUR déclare connaître et accepter les
              caractéristiques, les risques et les limites de l'internet et
              reconnaît notamment :
            </span>
          </p>
          <ul
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Être seul responsable de l'utilisation qu'il fait du PRODUIT,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Que l'internet présente des risques et des imperfections, qui
                  conduisent notamment à des baisses temporaires de ses
                  performances techniques, à l'augmentation des temps de réponse
                  lors de l'utilisation en ligne du PRODUIT, voire à
                  l'indisponibilité temporaire des serveurs,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Que la communication par l’UTILISATEUR du login et du mot de
                  passe attachés à son compte de connexion ou d'une manière
                  générale de toute information jugée confidentielle par
                  l’UTILISATEUR est faite sous sa propre responsabilité,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Qu’il appartient à l’UTILISATEUR de prendre toutes mesures
                  nécessaires pour s'assurer que les caractéristiques techniques
                  de ses supports électroniques et de ses logiciels lui
                  permettent l'utilisation du PRODUIT,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "8pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Qu’il appartient à l’UTILISATEUR de prendre toutes les mesures
                  appropriées pour protéger ses supports, systèmes, réseau et
                  ses propres données de la contamination par des éventuels
                  virus circulant à travers le PRODUIT, les CONTENUS et/ou les
                  données transmises lors des connexions.
                </span>
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              6.5.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;L’UTILISATEUR reconnaît que LISA CONNECT ne pourra en aucun
              cas être tenue pour responsable de tout dommage direct ou indirect
              de quelque nature que ce soit (notamment les pertes d'informations
              confidentielles ou autres informations, interruptions d'activité,
              préjudices corporels, atteintes à la vie privée, manquement à
              toute obligation pour des actes de négligence), et pour toute
              perte pécuniaire ou autre, résultant de, ou lié à l'utilisation ou
              l'impossibilité d'utiliser le PRODUIT, ou à la survenance d’un
              piratage informatique du PRODUIT, ou à l'exécution des obligations
              prévues par les CGU, même si LISA CONNECT a été prévenue de
              l'éventualité de tels dommages.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR reconnaît que LISA CONNECT ne saurait être tenue
              responsable de la destruction accidentelle des données de
              l’UTILISATEUR ou de tiers.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              6.6.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;L’UTILISATEUR s’engage :
            </span>
          </p>
          <ul
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  À ne pas faire usage du PRODUIT en violation des lois et
                  règlements,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  À garantir LISA CONNECT de toutes les conséquences, de quelque
                  nature qu’elles soient, en ce compris, à titre non limitatif,
                  les honoraires d'avocats, qui peuvent résulter de
                  l’utilisation du PRODUIT en violation des termes des
                  conditions d’utilisation prévues aux CGU,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "8pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  À n'utiliser les informations concernant le PRODUIT que pour
                  ses besoins personnels propres.
                </span>
              </p>
            </li>
          </ul>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR s’interdit de reproduire tout élément et toute
              information contenue dans le PRODUIT, à l’exception de ceux le
              concernant exclusivement, par quelque moyen que ce soit, sous
              quelque forme que ce soit et sur quelque support que ce soit.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 7 – OBLIGATIONS ET RESPONSABILITES DE LISA CONNECT
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              7.1.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;Le PRODUIT est un outil mis à disposition de l’UTILISATEUR à
              titre gracieux.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR reconnaît et accepte que LISA CONNECT ne s’engage en
              aucun cas à mettre à disposition de l’UTILISATEUR, le PRODUIT de
              façon permanente. Ainsi, LISA CONNECT peut de plein droit et à
              tout moment suspendre et/ou cesser de mettre à disposition tout ou
              partie du PRODUIT sans formalité aucune, ce que l’UTILISATEUR
              accepte. L’UTILISATEUR renonce à se prévaloir d’un quelconque
              préjudice ou réclamation pour ce motif.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              7.2.
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              &nbsp;LISA CONNECT n'assume aucune responsabilité de quelque
              nature que ce soit quant à l'utilisation par l’UTILISATEUR des
              CONTENUS, informations et des analyses mis à disposition dans le
              cadre de l’utilisation du PRODUIT. Lors du transfert par moyen de
              télécommunication ou par tous autres moyens, aucune responsabilité
              ne pourra être retenue contre LISA CONNECT en cas d’altération des
              informations ou des données durant le transfert.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LISA CONNECT ne saurait être tenue responsable de dommages
              résultant de la perte, de l’altération ou de toute utilisation
              frauduleuse de données, de la transmission accidentelle de virus
              ou autres éléments nuisibles, ainsi que de l’attitude ou
              comportement des UTILISATEURS ayant accès au PRODUIT.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LISA CONNECT ne peut être tenue responsable d’éventuels
              dysfonctionnements du système informatique et/ou du support
              électronique de l’UTILISATEUR à la suite de l’utilisation du
              PRODUIT.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              7.3.&nbsp;
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR est informé et reconnaît que les informations
              accessibles via le PRODUIT sont données à titre indicatif et ne
              sont pas nécessairement mises à jour en temps réel, sans que la
              responsabilité de LISA CONNECT puisse être recherchée ou engagée
              et sans que l’UTILISATEUR ou quiconque puisse prétendre avoir subi
              un quelconque préjudice.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Dans un souci de bon fonctionnement du PRODUIT, LISA CONNECT se
              réserve la possibilité de limiter la durée de disponibilité des
              données au profit des UTILISATEURS en fonction des capacités du
              système servant à la mise en œuvre du PRODUIT et au stockage des
              données, et ne s’engage pas à fournir l’exhaustivité des CONTENUS.
              LISA CONNECT ne saurait être tenue responsable d’un quelconque
              dommage lié à l’un de ces motifs.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              7.4.&nbsp;
            </span>
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Les dispositions du présent article resteront applicables à
              l'expiration des présentes.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 8 – DROIT DE PROPRIETE
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chaque UTILISATEUR reconnaît et accepte que le PRODUIT, les
              CONTENUS et tout logiciel utilisé nécessairement en relation avec
              le PRODUIT peuvent contenir des informations confidentielles et
              protégées par le droit de propriété intellectuelle en vigueur ou
              toute autre loi.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              La conclusion des CGU ne confère à l’UTILISATEUR aucun droit de
              propriété intellectuelle sur les données fournies par LISA CONNECT
              et/ou sur le PRODUIT, qui demeurent la propriété entière et
              exclusive de LISA CONNECT, ni sur les CONTENUS.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Le PRODUIT et l’ensemble de ses composants, y compris textes,
              images fixes ou animées, base de données, programmes, etc, sont
              protégés par le droit d’auteur. LISA CONNECT est propriétaire
              et/ou dispose de l’autorisation d’utiliser l’ensemble des droits
              portant sur les logos, marques, signes distinctifs, textes,
              illustrations, photographies, images, documents et vidéos ainsi
              que les bases de données et logiciels utilisés dans le cadre de
              l’exploitation du PRODUIT.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LISA CONNECT ne sous-concède indirectement à l’UTILISATEUR, qu’une
              autorisation non exclusive et non cessible, de visualisation des
              CONTENUS à titre personnel et privé, à l’exclusion de toute
              visualisation ou diffusion publique. L’autorisation de
              reproduction n’est concédée à l’UTILISATEUR que sous forme
              numérique sur leur support électronique aux fins de visualisation
              des pages consultées par leur navigateur internet. L’impression
              papier est autorisée aux fins de copie privée à l’usage exclusif
              du copiste au sens de l’article L.122-5, 2° du Code de la
              Propriété Intellectuelle.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Il est interdit à l’UTILISATEUR de copier, modifier, créer un
              produit dérivé, inverser la conception ou l'assemblage ou de toute
              autre manière tenter de trouver le code source, vendre, attribuer,
              sous-licencier ou transférer de quelque manière que ce soit tout
              droit afférent au PRODUIT.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chaque UTILISATEUR s’engage à ne pas accéder au PRODUIT par un
              autre moyen que fourni par LISA CONNECT à cet effet.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Par ailleurs, dans le cas où des créations susceptibles d’être
              protégées par le droit de la propriété intellectuelle seraient
              générées dans le cadre de l’utilisation du PRODUIT et/ou des
              CONTENUS, et notamment toute suggestion, demande d'amélioration,
              recommandation, correction incorporée dans le PRODUIT et tout
              autre retour fourni par l’UTILISATEUR, il est convenu que
              l’UTILISATEUR cèdera à LISA CONNECT, dès l’acceptation des CGU, à
              titre exclusif et au fur et à mesure de l’utilisation du PRODUIT
              et/ou des CONTENUS, l’ensemble des droits patrimoniaux afférents
              aux dites créations, pour la durée légale desdits droits et pour
              le monde entier. Ces droits comprennent notamment les droits de
              reproduction, d’utilisation, de représentation, de publication,
              d’édition, d’adaptation, de développement, de modification, de
              correction, d’intégration, de transcription, de traduction, de
              numérisation et de commercialisation sous toutes formes, en tout
              ou partie, par tout moyen et sur tous supports connus ou à venir.
              La cession des droits de propriété intellectuelle de l’UTILISATEUR
              étant irrévocable, LISA CONNECT pourra en disposer à son gré, les
              protéger par tout dépôt à son profit qu’il estimerait nécessaire,
              les exploiter comme bon lui semblera et à toutes fins, les
              adapter, les transférer librement à toute personne physique ou
              morale de son choix à titre gratuit ou onéreux ou, au contraire,
              les abandonner.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Toute autre utilisation non expressément visée n’est permise et
              nécessite l’accord préalable et écrit de LISA CONNECT.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 9 – DONNEES PERSONNELLES
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              DANS LE CADRE DE L’UTILISATION DU PRODUIT, LES FLUX TRANSITANT
              SONT ENREGISTRÉS DANS DES BASES DE DONNÉES.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR ACCEPTE QUE LISA CONNECT PUISSE À SA CONVENANCE
              AGRÉGER ET SUPPRIMER LES DONNÉES PROPRIÉTÉ DE L’UTILISATEUR AUX
              FINS DE LIMITER LE VOLUME DE DONNÉES STOCKÉES SUR LE PRODUIT A LA
              SEULE DISCRÉTION DE LISA CONNECT, ET QUE LISA CONNECT NE S’ENGAGE
              PAS À FOURNIR L'EXHAUSTIVITÉ DES DONNÉES MISES À DISPOSITION SUR
              LE PRODUIT. IL EST EXPRESSÉMENT CONVENU QUE LISA CONNECT NE
              SAURAIT ÊTRE TENU RESPONSABLE D’UN QUELCONQUE DOMMAGE LIÉ À L’UN
              DE CES MOTIFS.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LE RESPONSABLE DU TRAITEMENT DES DONNÉES EST LISA CONNECT.&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              DANS LE CADRE DE LA COLLECTE ET DU TRAITEMENT DES DONNEES A
              CARACTERE PERSONNEL AUX FINS D'EXÉCUTION DES PRÉSENTES, LISA
              CONNECT S’ENGAGE À RESPECTER LES PRINCIPES ET OBLIGATIONS
              APPLICABLES AU RESPONSABLE DE TRAITEMENT, TELS QUE PRÉVUS PAR LE
              RÈGLEMENT 2016/679/UE DU 27 AVRIL 2016 RELATIF À LA PROTECTION DES
              DONNEES (CI-APRES DENOMME «&nbsp;RGPD&nbsp;»&nbsp;, ET LA LOI
              INFORMATIQUE ET LIBERTÉS N°78-17 DU 6 JANVIER 1978 MODIFIÉE
              RELATIVE À L'INFORMATIQUE, AUX FICHIERS ET AUX LIBERTÉS DANS SA
              VERSION EN VIGUEUR.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR RECONNAÎT AVOIR PRIS CONNAISSANCE ET ACCEPTER
              EXPRESSÉMENT LES STIPULATIONS RELATIVES A «&nbsp;LA POLITIQUE DE
              PROTECTION DES DONNÉES PERSONNELLES DES UTILISATEURS&nbsp;»
              FIGURANT EN ANNEXE&nbsp;I/I DES CGU.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              CONFORMEMENT A LA LOI N° 78-17 DU 6 JANVIER 1978 PRÉCITÉE, LES
              INFORMATIONS À CARACTÈRE NOMINATIF RELATIVES AUX UTILISATEURS
              POURRONT FAIRE L’OBJET D’UN TRAITEMENT AUTOMATISÉ.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LA SOCIÉTÉ LISA CONNECT SE RÉSERVE LE DROIT DE COLLECTER DES
              INFORMATIONS CONCERNANT L’UTILISATEUR, Y COMPRIS VIA L’UTILISATION
              DE COOKIES.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LES DONNÉES PERSONNELLES RECUEILLIES AUPRÈS DES UTILISATEURS DANS
              LE CADRE DE L’EXECUTION DES PRESENTES, FONT L'OBJET D'UN
              TRAITEMENT INFORMATIQUE RÉALISÉ PAR LISA CONNECT. ELLES SONT
              ENREGISTRÉES DANS SON FICHIER UTILISATEURS ET SONT INDISPENSABLES
              À SON UTILISATION DU PRODUIT. CES INFORMATIONS ET DONNÉES
              PERSONNELLES SONT ÉGALEMENT CONSERVÉS À DES FINS DE SÉCURITÉ, AFIN
              DE RESPECTER LES OBLIGATIONS LEGALES ET REGLEMENTAIRES. ELLES
              SERONT CONSERVÉES AUSSI LONGTEMPS QUE NÉCESSAIRE POUR L'EXÉCUTION
              DES COMMANDES ET DES GARANTIES ÉVENTUELLEMENT APPLICABLES.
            </span>
          </p>

          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L'ACCÈS AUX DONNÉES PERSONNELLES SERA STRICTEMENT LIMITÉ AUX
              EMPLOYÉS DU RESPONSABLE DE TRAITEMENT, HABILITÉS À LES TRAITER EN
              RAISON DE LEURS FONCTIONS. LES INFORMATIONS RECUEILLIES POURRONT
              ÉVENTUELLEMENT ÊTRE COMMUNIQUÉES À DES TIERS LIÉS À L'ENTREPRISE
              PAR CONTRAT POUR L'EXÉCUTION DE TÂCHES SOUS-TRAITÉES, SANS QUE
              L'AUTORISATION DE L’UTILISATEUR SOIT NÉCESSAIRE. &nbsp;&nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              DANS LE CADRE DE L'EXÉCUTION DE LEURS PRESTATIONS, LES TIERS N'ONT
              QU'UN ACCÈS LIMITÉ AUX DONNÉES ET ONT L'OBLIGATION DE LES UTILISER
              EN CONFORMITÉ AVEC LES DISPOSITIONS DE LA LÉGISLATION APPLICABLE
              EN MATIÈRE DE PROTECTION DES DONNÉES PERSONNELLES. EN DEHORS DES
              CAS ÉNONCÉS CI-DESSUS, LISA CONNECT S'INTERDIT DE VENDRE, LOUER,
              CÉDER OU DONNER ACCÈS À DES TIERS AUX DONNÉES SANS CONSENTEMENT
              PRÉALABLE DE L’UTILISATEUR, À MOINS D'Y ÊTRE CONTRAINTE EN RAISON
              D'UN MOTIF LÉGITIME. &nbsp;
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              SI LES DONNÉES SONT AMENEES A ETRE TRANSFEREES EN DEHORS DE L'UE,
              L’UTILISATEUR EN SERA INFORMÉ ET LES GARANTIES PRISES AFIN DE
              SÉCURISER LES DONNÉES LUI SERONT PRÉCISÉES.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              CONFORMEMENT A LA REGLEMENTATION APPLICABLE, L’UTILISATEUR DISPOSE
              D'UN DROIT D'ACCÈS, DE RECTIFICATION, D'EFFACEMENT, ET DE
              PORTABILITÉ DES DONNÉES LE CONCERNANT, AINSI QUE DU DROIT DE
              S'OPPOSER AU TRAITEMENT POUR MOTIF LÉGITIME, DROITS QU'IL PEUT
              EXERCER EN S'ADRESSANT AU RESPONSABLE DE TRAITEMENT VIA LE MODULE
              INTÉGRÉ AU SEIN DU PRODUIT (PICTOGRAMME REPRÉSENTANT UN POINT
              D’INTERROGATION), OU À L'ADRESSE POSTALE OU ÉLECTRONIQUE SUIVANTE
              :&nbsp;
            </span>
          </p>
          <ul
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2999999999999998",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  SAS LISA CONNECT, RCS TROYES N°882 662 190, SISE 53 RUE DE LA
                  PAIX – 10000 TROYES,&nbsp;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2999999999999998",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <a
                  href="mailto:contact@lisaconnect.fr"
                  style={{ textDecoration: "none" }}
                >
                  <span
                    style={{
                      fontSize: "11pt",
                      fontFamily: "Cambria,serif",
                      color: "#1155cc",
                      backgroundColor: "transparent",
                      fontWeight: 700,
                      fontStyle: "normal",
                      fontVariant: "normal",
                      textDecoration: "underline",
                      WebkitTextDecorationSkip: "none",
                      textDecorationSkipInk: "none",
                      verticalAlign: "baseline",
                      whiteSpace: "pre-wrap",
                    }}
                  >
                    CONTACT@LISACONNECT.FR
                  </span>
                </a>
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 700,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  .&nbsp;
                </span>
              </p>
            </li>
          </ul>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2999999999999998",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              EN CAS DE DÉSACCORD, L’UTILISATEUR PEUT ADRESSER UNE RÉCLAMATION
              AUPRÈS DU DÉLÉGUÉ À LA PROTECTION DES DONNÉES PERSONNELLES DE LISA
              CONNECT ET/OU DE LA COMMISSION NATIONALE DE L'INFORMATIQUE ET DES
              LIBERTÉS.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 10 – RESILIATION
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chaque UTILISATEUR reconnaît à LISA CONNECT, le droit de mettre
              fin , sans formalité ni préavis, à tout ou partie du droit d'accès
              correspondant à son compte de connexion, voire de supprimer ses
              identifiant et mot de passe, ainsi que le droit de retirer ou de
              déplacer toute information sur le PRODUIT et ce pour tout motif
              notamment en raison de l'absence d'utilisation, ou si LISA CONNECT
              a de bonnes raisons de croire qu’un UTILISATEUR a violé ou agi en
              contradiction avec la lettre ou l'esprit des CGU.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LISA CONNECT peut également unilatéralement et à tout moment
              interrompre la fourniture du PRODUIT. Chaque UTILISATEUR reconnaît
              que toute résiliation de son accès au PRODUIT en application des
              termes des présentes peut intervenir sans mise en demeure
              préalable, et reconnaît et accepte que LISA CONNECT sera en droit
              de désactiver ou supprimer à tout moment et avec effet immédiat
              son compte de connexion et/ou interdire tout accès ultérieur au
              PRODUIT.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              De plus chaque UTILISATEUR reconnaît que LISA CONNECT ne pourra
              être tenue pour responsable à son encontre ou à l'encontre de
              tiers, pour toute résiliation de son accès au PRODUIT.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 11 – GARANTIES
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Chaque UTILISATEUR reconnaît et accepte expressément que :
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              1. L’UTILISATION DU PRODUIT SE FAIT SOUS SA RESPONSABILITÉ, LE
              PRODUIT ÉTANT FOURNI SUR LA BASE D'UN PRODUIT « EN L'ÉTAT » ET
              ACCESSIBLE EN FONCTION DE SA DISPONIBILITÉ.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              2. LISA CONNECT NE GARANTIT PAS QUE :
            </span>
          </p>
          <ul
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  LE PRODUIT SERA ININTERROMPU, OPPORTUN, SÛR OU DÉPOURVU DE
                  TOUTE ERREUR,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  LES RÉSULTATS QUI PEUVENT ÊTRE OBTENUS EN UTILISANT LE PRODUIT
                  SERONT EXACTS.
                </span>
              </p>
            </li>
          </ul>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 12 – LIMITATION DE RESPONSABILITE
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              CHAQUE UTILISATEUR RECONNAÎT ET ACCEPTE QUE, DANS TOUTE LA MESURE
              PERMISE PAR LA RÉGLEMENTATION EN VIGUEUR, LISA CONNECT NE POURRA
              ÊTRE TENUE POUR RESPONSABLE DE TOUT DOMMAGE DIRECT OU INDIRECT EN
              CE Y COMPRIS NOTAMMENT LES PERTES DE DONNÉES OU TOUTE AUTRE PERTE
              DE BIENS POUVANT SURVENIR&nbsp;:
            </span>
          </p>
          <ul
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  DE L'UTILISATION OU AU CONTRAIRE DE L'IMPOSSIBILITÉ D'UTILISER
                  LE
                </span>
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  <span
                    className="Apple-tab-span"
                    style={{ whiteSpace: "pre" }}
                  >
                    &nbsp; &nbsp;&nbsp;
                  </span>
                </span>
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  PRODUIT,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  SUITE A UN ACCES NON AUTORISE AU PRODUIT PAR UN UTILISATEUR.
                </span>
              </p>
            </li>
          </ul>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 13 – CONFIDENTIALITE
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Il est expressément convenu que toutes les informations présentes
              sur le PRODUIT et/ou communiquées dans le cadre de l’utilisation
              du PRODUIT conforme aux CGU sont soumises à une obligation de
              confidentialité incombant à l’UTILISATEUR, qui s’interdit donc de
              communiquer lesdites informations à tous tiers.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 14 – INCESSIBILITE
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR ne pourra céder tout ou partie des droits et
              obligations résultant des CGU.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 15 – NON-VALIDITÉ PARTIELLE – NON-RENONCIATION
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              Le fait pour l’une des Parties de ne pas faire appliquer l’un de
              ses droits ou de ne pas exiger l’exécution de l’une quelconque des
              obligations ou responsabilités incombant à l’autre Partie, en
              vertu des CGU, ne pourra pas être considéré en soi comme
              renonciation par ladite Partie à ses droits, obligations et
              responsabilités découlant des CGU. Dans l'hypothèse où l'une
              quelconque des dispositions des CGU serait annulée par une
              décision de justice, les Parties s'accordent à guider le juge de
              bonne foi pour que l'appréciation du juge tienne compte de la
              commune intention des parties manifestée par les CGU, les autres
              dispositions demeurant valables et continuant de s'appliquer entre
              les Parties. Les titres des articles n'ont qu'une valeur
              indicative.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 16 – LITIGES - LOI APPLICABLE - TRIBUNAL COMPETENT
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LES CGU ET LA RELATION ENTRE LISA CONNECT ET CHAQUE UTILISATEUR
              SONT SOUMISES AU DROIT FRANÇAIS, À L'EXCLUSION DE TOUTE AUTRE
              LÉGISLATION.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.26",
              textAlign: "justify",
              marginTop: "0.05pt",
              marginBottom: "0pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR DEVRA AU PRÉALABLE PRÉSENTER SES EVENTUELLES
              RÉCLAMATIONS AUPRÈS DU SERVICE AIDE/RECLAMATIONS DE LISA CONNECT
              AFIN DE RECHERCHER UNE SOLUTION AMIABLE.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              TOUS LES LITIGES AUXQUELS L’UTILISATION DU PRODUIT EN APPLICATION
              DES PRESENTES CONDITIONS GENERALES D’UTILISATION POURRAIENT DONNER
              LIEU, CONCERNANT TANT LEUR VALIDITE, LEUR INTERPRETATION, LEUR
              EXECUTION, LEUR RESILIATION, LEURS CONSEQUENCES ET LEURS SUITES ET
              QUI N'AURAIENT PU TROUVER UNE ISSUE TRANSACTIONNELLE ENTRE LISA
              CONNECT ET L’UTILISATEUR SERONT SOUMIS AUX TRIBUNAUX COMPÉTENTS
              DANS LES CONDITIONS DE DROIT COMMUN.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR EST INFORMÉ QU'IL PEUT EN TOUT ÉTAT DE CAUSE
              RECOURIR A UNE MÉDIATION CONVENTIONNELLE, OU À TOUT MODE
              ALTERNATIF DE RÈGLEMENT DES DIFFÉRENDS (NOTAMMENT CONCILIATION) EN
              CAS DE CONTESTATION.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR, CONSTATANT QU'UNE VIOLATION AU REGLEMENT GENERAL
              SUR LA PROTECTION DES DONNÉES PERSONNELLES AURAIT ÉTÉ COMMISE, A
              LA POSSIBILITÉ DE MANDATER UNE ASSOCIATION OU UN ORGANISME
              MENTIONNÉ AU IV DE L'ARTICLE 43 TER DE LA LOI INFORMATIQUE ET
              LIBERTE DE 1978, AFIN D'OBTENIR CONTRE LE RESPONSABLE DE
              TRAITEMENT OU SOUS-TRAITANT, RÉPARATION DEVANT UNE JURIDICTION
              CIVILE OU ADMINISTRATIVE OU DEVANT LA COMMISSION NATIONALE DE
              L'INFORMATIQUE ET DES LIBERTÉS.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ARTICLE 17 - INFORMATION PRÉCONTRACTUELLE - ACCEPTATION DE
              L’UTILISATEUR
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LES CGU ET LEUR ANNEXE I/I CONSTITUENT L'INTÉGRALITÉ DE L’ACCORD
              ENTRE LES PARTIES EU ÉGARD À SON OBJET. EN CONSÉQUENCE, ILS
              REMPLACENT ET ANNULENT TOUTES DÉCLARATIONS, NÉGOCIATIONS,
              ENGAGEMENTS, COMMUNICATIONS ORALES OU ÉCRITES, ACCEPTATIONS, ET
              ACCORDS PRÉALABLES ENTRE LES PARTIES, RELATIVEMENT AU MÊME OBJET.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              AUCUNE MODIFICATION DES CGU N’EST ADMISE AVANT ACCORD, SIGNATURE
              ET ENTRÉE EN VIGUEUR D’UN AVENANT SIGNE DES DEUX PARTIES.
            </span>
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              L’UTILISATEUR RECONNAÎT AVOIR EU COMMUNICATION, PRÉALABLEMENT À
              L’UTILISATION DU PRODUIT ET A LA CONCLUSION DU CONTRAT, D'UNE
              MANIÈRE LISIBLE ET COMPRÉHENSIBLE, DES PRÉSENTES CONDITIONS
              GÉNÉRALES D’UTILISATION ET DE TOUTES LES INFORMATIONS NÉCESSAIRES,
              À SAVOIR :&nbsp;
            </span>
          </p>
          <ul
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  LES CARACTÉRISTIQUES ESSENTIELLES DU PRODUIT,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  LES INFORMATIONS RELATIVES À L'IDENTITÉ DE LISA CONNECT, A SES
                  COORDONNÉES POSTALES, TÉLÉPHONIQUES ET ÉLECTRONIQUES, ET À SES
                  ACTIVITÉS, SI ELLES NE RESSORTENT PAS DU CONTEXTE,&nbsp;
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  LES FONCTIONNALITÉS DU PRODUIT ET, LE CAS ÉCHÉANT, À SON
                  INTEROPÉRABILITÉ,
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "disc",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.3800000000000001",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  LA POSSIBILITÉ DE RECOURIR UNE MÉDIATION CONVENTIONNELLE EN
                  CAS DE LITIGE.
                </span>
              </p>
            </li>
          </ul>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.3800000000000001",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "10pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              LE FAIT POUR UNE PERSONNE PHYSIQUE, D’UTILISER LE PRODUIT EMPORTE
              ADHÉSION ET ACCEPTATION PLEINE, ENTIÈRE ET SANS RÉSERVES DES CGU
              ET OBLIGATION À L'EXÉCUTION DES OBLIGATIONS LUI INCOMBANT AU TITRE
              DES CGU, CE QUI EST EXPRESSÉMENT RECONNU PAR L’UTILISATEUR, QUI
              RENONCE, NOTAMMENT, À SE PREVALOIR DE TOUT DOCUMENT
              CONTRADICTOIRE, QUI SERAIT INOPPOSABLE À LISA CONNECT.
            </span>
          </p>
          <p>
            <br />
          </p>
          <p
            dir="ltr"
            style={{
              lineHeight: "1.2",
              textAlign: "justify",
              marginTop: "0pt",
              marginBottom: "8pt",
            }}
          >
            <span
              style={{
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 700,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "underline",
                WebkitTextDecorationSkip: "none",
                textDecorationSkipInk: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre-wrap",
              }}
            >
              ANNEXE I/I&nbsp;: POLITIQUE DE PROTECTION DES DONNEES PERSONNELLES
              DES UTILISATEURS
            </span>
          </p>
          <ol
            style={{
              marginTop: 0,
              marginBottom: 0,
              paddingInlineStart: "48px",
            }}
          >
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Qui est le responsable du traitement de mes données
                  personnelles&nbsp;?
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Pourquoi avez-vous besoin de mes données personnelles&nbsp;?
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Comment avez-vous collecté mes données personnelles&nbsp;?
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Quelles catégories de données personnelles&nbsp;me concernant
                  traitez-vous&nbsp;?
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Avez-vous recours à des sous-traitants pour leur
                  traitement&nbsp;?
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Qui a accès à mes données personnelles&nbsp;?
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Où mes données personnelles sont-elles stockées&nbsp;?
                  Sont-elles transférées en dehors de l’Union européenne&nbsp;?
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Combien de temps conservez-vous mes données
                  personnelles&nbsp;?
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Mes données personnelles&nbsp;seront-elles utilisées pour
                  effectuer des décisions individuelles, automatisées ou un
                  profilage&nbsp;?
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Quels sont les droits concernant mes données
                  personnelles&nbsp;?
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "0pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Que se passera-t-il si je m’oppose au traitement de mes
                  données personnelles&nbsp;?
                </span>
              </p>
            </li>
            <li
              dir="ltr"
              style={{
                listStyleType: "decimal",
                fontSize: "11pt",
                fontFamily: "Cambria,serif",
                color: "#000000",
                backgroundColor: "transparent",
                fontWeight: 400,
                fontStyle: "normal",
                fontVariant: "normal",
                textDecoration: "none",
                verticalAlign: "baseline",
                whiteSpace: "pre",
              }}
              aria-level={1}
            >
              <p
                dir="ltr"
                style={{
                  lineHeight: "1.2",
                  textAlign: "justify",
                  marginTop: "0pt",
                  marginBottom: "8pt",
                }}
              >
                <span
                  style={{
                    fontSize: "11pt",
                    fontFamily: "Cambria,serif",
                    color: "#000000",
                    backgroundColor: "transparent",
                    fontWeight: 400,
                    fontStyle: "normal",
                    fontVariant: "normal",
                    textDecoration: "none",
                    verticalAlign: "baseline",
                    whiteSpace: "pre-wrap",
                  }}
                >
                  Comment puis-je exercer mes droits et qui dois-je
                  contacter&nbsp;?
                </span>
              </p>
            </li>
          </ol>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
          <p>
            <br />
          </p>
        </div>
      </div>
    </section>
  );
}

export default CgvPage;
