import React, { useState } from "react";
import "../style/confirmStyle.scss";

function ConfirmPage(props) {
  const [data, setData] = useState(props.txt);

  return (
    <section id="confirmPage">
      <div id="mainBloc">
        <div id="title">{data.title}</div>
        <div id="txt">
          {data.text.map((txt, key) => {
            return <p key={key}>{txt}</p>;
          })}
        </div>
      </div>
    </section>
  );
}

export default ConfirmPage;
