import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../config";
import { useParams } from "react-router-dom";
import "../style/magsStyle.scss";
import ConfirmPage from "./confirmPage";
import CountUp from "react-countup";
import AllMags from "../components/allMags";

const MagazinePage = () => {
  const [magazines, setMagazines] = useState([]);
  const [urlTkn, setUrlTkn] = useState("");
  const [error, setError] = useState(null);
  let { tkn } = useParams();
  const [token, setToken] = useState(null);

  const errorData = {
    title: "Le service est actuellement indisponible.",
    text: [
      `Votre temps de connexion de 3 heures est peut-être expiré.`,
      `Merci de réessayer en cliquant sur le lien reçu par mail, ou renouvelez l'Inscription.`,
    ],
  };

  useEffect(() => {
    setToken(tkn);
  }, [tkn]);

  useEffect(() => {
    if (token) {
      axios
        .get(`${config.api}/mags/${token}`, {
          headers: {
            "Content-Type": "application/json",
          },
          withCredentials: true,
        })
        .then((response) => {
          if (response.data.error) {
            setError(response.data.error.message);
          } else {
            setUrlTkn(response.data.token);
            setMagazines(response.data.data);
          }
        })
        .catch((error) => {
          setError(error.message);
        });
    }
  }, [token]);

  if (error) {
    return <ConfirmPage txt={errorData} />;
  }

  const separator = () => {
    return <span className="separator"></span>;
  };

  return (
    <section className="magPage">
      <div id="stats" className="table">
        <div className="pageTitlesBlocs">
          {separator()}
          <h2 className="pageTitlesTxt">Lisaconnect</h2>
          {separator()}
        </div>
        <div className="lisaBloc">
          <div className="lisaBlocList">
            <span className="lcChiffres ">
              <CountUp end={2} duration={2} /> M
            </span>
            <span className="lcTitres ">PATIENTS</span>
            <span className="lcPhrase">
              <br />
              ont déjà rencontré Lisa <br />
            </span>
          </div>
        </div>
        <div className="lisaBloc">
          <div className="lisaBlocList">
            <span className="lcChiffres chiffre2">
              <CountUp end={250} duration={3} /> M
            </span>
            <span className="lcTitres">MESSAGES</span>
            <span className="lcPhrase">
              vidéos de prévention diffusées <br /> chaque année
              <br />
            </span>
          </div>
        </div>

        <div className="lisaBloc">
          <div className="lisaBlocList">
            <span className="lcChiffres ">
              <CountUp end={20000} duration={3} />
            </span>
            <span className="lcTitres">IMPRESSIONS</span>
            <span className="lcPhrase">
              Sudoku, Origami, Histoires courtes
              <br />
              à retrouver sur votre Lisa
              <br />
            </span>
          </div>
        </div>
        <div className="lisabtn-blc">
          <a href="#magazines">
            <button className="lisabtn">Voir les magazines</button>
          </a>
        </div>
      </div>
      <div id="magazines" className="table">
        <div className="pageTitlesBlocs">
          {separator()}
          <h2 className="pageTitlesTxt">Catalogue</h2>
          {separator()}
        </div>
        <AllMags magazines={magazines} tkn={urlTkn} />
      </div>
    </section>
  );
};

export default MagazinePage;
