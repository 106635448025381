import React from "react";
import LisaLogo from "../assets/images/lisaconnect.png";
import LisaBorneEntoure3 from "../assets/images/lisa_dessin_entoure_3.png";
import YoutubeEmbed from "../components/youtubeWidget";
import MagForm from "../components/magForm";
import "../style/formStyle.scss";
// import { useNavigate } from "react-router-dom";

function FormPage() {
  // const navigate = useNavigate();

  return (
    <section id="formPage">
      <img src={LisaLogo} alt="logo lisaconnect" id="form-logo" />
      <div id="form-txt">
        {/* <YoutubeEmbed /> */}
        <span>
          Bienvenu sur la plateforme de <b>magazines digitaux</b> de
          LisaConnect.
          <br /> <br />
          Afin de profiter <b>gratuitement</b> de ce service, veuillez vous
          inscrire dans le <b>formulaire</b> ci-dessous. <br />
          Vous devrez renseigner votre adresse email, ainsi que le{" "}
          <b>code d'accès affiché sur l'écran du bas de la borne Lisa</b> dans
          votre salle d'attente.
          <br />
        </span>
        <img src={LisaBorneEntoure3} alt="borne lisa" id="form-borne" />
      </div>
      <div>
        <MagForm />
      </div>
    </section>
  );
}

export default FormPage;
